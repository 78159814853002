<template>
  <v-container>
    <v-row justify="center">
      <v-toolbar min-width="100%" dark color="primary">
        <v-card-title>SMS Report </v-card-title>
        <v-card-title class="ml-auto" style="padding-left: 2%"
          >Available SMS Qty: &nbsp;
          <span style="color: greenyellow">{{ remainData }}</span>
        </v-card-title>
      </v-toolbar>
      <v-col cols="12" md="8" lg="12">
        <DataTableSSR
          apiEndPoint="/mainsms/"
          :headers="headers"
          :instantLoad="true"
        >
          <template #[`item.title`]="{ item }">
            <!-- <router-link :to="'/sms/' + item.id"> -->
            Send SMS
            <!-- </router-link> -->
          </template>

          <template #[`item.sms_send`]="{ item }"> {{item}} </template>

          <template #[`item.created_at`]="{ item }">
            
            {{ new Date(item.created_at).toLocaleString() }}
          </template>
          <template #[`item.action`]="{ item }">
            <v-dialog v-model="item.dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  view result
                </v-btn>
              </template>

              <v-card>
                <v-row>
                  <v-col flex:6>
                    <v-card-title> Sent SMS </v-card-title>

                    <v-card-text>
                      {{ sendData }}
                    </v-card-text>
                  </v-col>

                  <v-col flex:6>
                    <v-card-title> Unsent SMS </v-card-title>

                    <v-card-text>
                      {{ unsendData }}
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="item.dialog = false">
                    close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </DataTableSSR>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableSSR from "../../../components/global/DataTableSSR.vue";
export default {
  components: { DataTableSSR },
  data() {
    return {
      headers: [
        {
          text: "Title",
          value: "title",
        },

        {
          text: "Created",
          value: "created_at",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
        },
      ],
      sendData: {},
      remainData: {},
      dialog: false,
    };
  },
  mounted() {
    this.loadSendSms();
    this.loadUnSendSms();
    this.loadRemain();
  },
  methods: {
    loadSendSms() {
      this.$api.get("send/send-number/").then((res) => {
        this.sendData = res.data;
      });
    },
    loadRemain() {
      this.$api.get("mainsms/remain_sms/").then((res) => {
        this.remainData = res.data;
      });
    },
    loadUnSendSms() {
      this.$api.get("unsend/unsend-number/").then((res) => {
        this.unsendData = res.data;
      });
    },
  },
};
</script>

<style></style>
